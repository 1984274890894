{
  "name": "file-monitoring-app",
  "version": "1.5.3-f033be33.0",
  "scripts": {
    "ng": "node node_modules/@angular/cli/bin/ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --code-coverage",
    "lint": "ng lint",
    "install": "cp ./package.json ./src/assets",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.4",
    "@angular/cdk": "^17.0.1",
    "@angular/common": "^17.0.4",
    "@angular/compiler": "^17.0.4",
    "@angular/core": "^17.0.4",
    "@angular/forms": "^17.0.4",
    "@angular/material": "^17.0.1",
    "@angular/platform-browser": "^17.0.4",
    "@angular/platform-browser-dynamic": "^17.0.4",
    "@angular/router": "^17.0.4",
    "core-js": "^3.7.0",
    "rxjs": "~7.5.0",
    "tslib": "^2.0.0",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.0/xlsx-0.20.0.tgz",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.3",
    "@angular-eslint/builder": "^17.1.0",
    "@angular-eslint/eslint-plugin": "^17.1.0",
    "@angular-eslint/eslint-plugin-template": "^17.1.0",
    "@angular-eslint/schematics": "^17.1.0",
    "@angular-eslint/template-parser": "^17.1.0",
    "@angular/cli": "^17.0.3",
    "@angular/compiler-cli": "^17.0.4",
    "@angular/language-service": "^17.0.4",
    "@angular/material-luxon-adapter": "^17.0.1",
    "@sparbanken-syd/personnummer": "^2.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.0.4",
    "@types/jasmine": "^4.3.5",
    "@types/jasminewd2": "~2.0.3",
    "@types/node": "~14.14.6",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.53.0",
    "eslint-plugin-import": "2.25.2",
    "eslint-plugin-jsdoc": "30.7.6",
    "eslint-plugin-prefer-arrow": "1.2.2",
    "jasmine-core": "^5.0.1",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "~6.3.2",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "luxon": "^3.2.0",
    "mocha": "^10.2.0",
    "postcss": "^8.4.21",
    "postcss-scss": "^4.0.6",
    "stylelint": "^15.10.1",
    "stylelint-config-standard": "^34.0.0",
    "stylelint-config-standard-scss": "^10.0.0",
    "ts-node": "~9.0.0",
    "typescript": "5.2.2"
  }
}
