import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {first, switchMap} from 'rxjs'
import {ConfigService} from '../services/config.service'

/**
 * Inject to set auth header on all requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * @param configService - Knows if we have a token or not.
   */
  constructor(private configService: ConfigService) {

  }

  /**
   * Intercept all requests and add auth token. We should however
   * do this only on white listed requests so that we do not
   * send our tokens when not needed. In its simplest form this
   * assumes that all outgoing requests after the token is set
   * should have it.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.configService.accessToken$.pipe(
      first(),
      switchMap(token => {
        if (!token) {
          return next.handle(req)
        }
        const authReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`)
        })
        return next.handle(authReq)
      })
    )
  }
}
